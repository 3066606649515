<template>
  <ion-card class="favourite-episode">
    <AppImg
      :img-src="banner"
      img-alt="banner"
      border-radius="10px"
      class="banner"
    />
    <ion-card-header class="headers">
      <ion-card-subtitle
        class="company-title"
        :title="item.company_name"
      >
        {{ truncatedText(item.company_name) }}
      </ion-card-subtitle>
      <ion-card-title
        class="course-title"
        :title="item.course_title"
      >
        {{ truncatedText(item.course_title) }}
      </ion-card-title>
    </ion-card-header>

    <ion-label
      v-if="item.streams?.[0]?.duration || item?.duration"
      class="episode-duration"
    >
      {{ Math.round((item.streams?.[0]?.duration || item?.duration) / 60) }} {{ $t('podcast.min') }}
    </ion-label>
  </ion-card>
</template>

<script setup>
import { computed } from 'vue';
import { defineProps } from 'vue';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonLabel } from '@ionic/vue';
import AppImg from '@/components/common/AppImg.vue';
import { getSmallestSizeImage } from "@/helpers/functions";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const banner = computed(() => getSmallestSizeImage(props.item?.media)?.url ?? null);

const truncatedText = (text) => {
  return text?.length > 40 ? text?.substring(0, 37) + '...' : text;
};
</script>

<style scoped lang="scss">
.favourite-episode {
  width: 185px;
  height: 205px;
  border-radius: 10px;
  padding: 10px;
  margin: 0;
  background: #2E2E2E;

  .banner {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    object-fit: cover;

    :deep(img) {
      height: 100%;
      width: 100%;
    }
  }
}

.headers{
  margin: 0;
  padding: 0;
}

.company-title, .course-title {
  text-align: start;
}

.company-title {
  font-size: 10px;
  font-weight: normal;
  margin-top: 10px;
  color: #D6D6D6;
}

.course-title {
  font-size: 12px;
  margin-top: 8px;
}

.episode-duration {
  font-size: 8px;
  color: white;
  position: absolute;
  bottom: 5px;
  right: 10px;
}
</style>
