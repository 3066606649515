<template>
  <AppImg
    :img-src="banner"
    img-alt="banner"
    border-radius="10px"
    class="banner"
    :class="{ 'banner-mobile-small': mobileSmall }"
  />
</template>

<script setup>
import { computed } from '@vue/reactivity';
import AppImg from '@/components/common/AppImg.vue';
import { getSmallestSizeImage } from "@/helpers/functions";
import { defineProps } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  mobileSmall: {
    type: Boolean,
    default: false,
  },
});

const banner = computed(() => getSmallestSizeImage(props.item?.media)?.url ?? null);
</script>

<style scoped lang="scss">
 @import "src/theme/custom";

  .banner{
    width: 220px;
    height: 220px;
    border-radius: 10px;
    object-fit: cover;

    :deep(img) {
      height: 100%;
      width: 100%;
    }
  }

  .banner-mobile-small{
    @media screen and (max-width: $tablet-breakpoint) {
      width: 120px;
      height: 120px;
    }
  }
</style>